import Chip, { ChipProps } from '@mui/material/Chip';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { colors } from '@automata/mui-theme';
import { Typography } from '../..';
import { Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

// Simply overriding the props doesn't seem to work here
// TODO: Investigate why tsc fails on run-instructions if we don't omit sx
export type TagProps = Omit<ChipProps, 'color' | 'label' | 'sx'> & {
  color: string;
  label: string;
  isClickable?: boolean;
};

export type TriangleProps = {
  borderColor?: string;
  backgroundColor?: string;
};

const height = 24;
const width = 88;
const triangleWidth = 8;
const chipWidth = width - triangleWidth;
const borderWidth = 1;
const buttonSize = 13;
const localProps = [
  'borderColor',
  'backgroundColor',
  'isDisabled',
  'isClickable',
];

const ChipComponent = styled(Chip, {
  shouldForwardProp: (prop) =>
    typeof prop === 'string' && !localProps.includes(prop),
})<{
  borderColor?: string;
  backgroundColor?: string;
  isDisabled?: boolean;
  isClickable?: boolean;
}>`
  width: ${chipWidth}px;
  height: ${height}px;
  border-width: ${borderWidth}px;
  border-style: solid;
  border-color: ${(p) => p.borderColor} !important;
  border-left: 0;
  background-color: ${(p) => p.backgroundColor};
  border-radius: 0 4px 4px 0;
  transition: none;
  justify-content: space-between;
  cursor: ${(p) => (p.isClickable ? 'pointer' : 'default')};

  & .MuiChip-label {
    padding-left: ${(p) => p.theme.spacing(0.5)};
    padding-right: ${(p) => p.theme.spacing(1)};
    // To keep the ellipsis the same color as the text
    color: ${(p) => (p.isDisabled ? p.borderColor : 'initial')};
    // To align the text to the right and center vertically
    text-align: end;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .MuiChip-label > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DeleteIcon = styled(CloseIcon)`
  width: ${buttonSize}px;
  height: ${buttonSize}px;
`;

export const Tag = ({
  label,
  color,
  disabled,
  isClickable,
  ...otherChipProps
}: TagProps): JSX.Element => {
  const { categorical } = colors;

  if (!categorical.hasOwnProperty(color)) {
    throw new Error(`Color ${color} is not defined in the theme`);
  }
  const categoricalColor = categorical[color as keyof typeof categorical];

  const borderColor = disabled
    ? colors.interactions.disabledParts
    : categoricalColor.main;

  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    disabled ? colors.interactions.disabledBg : (categoricalColor[25] as string)
  );

  const darken = () => {
    if (disabled) return;
    setBackgroundColor(categoricalColor[50]);
  };

  const lighten = useCallback(() => {
    if (disabled) return;
    setBackgroundColor(categoricalColor[25]);
  }, [categoricalColor, disabled]);

  useEffect(() => {
    // Update the background color when the color prop changes
    // (e.g. when a new Tag is created with a random color)
    lighten();
  }, [color, lighten]);

  return (
    <Tooltip title={label} placement="top">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        onMouseEnter={darken}
        onMouseLeave={lighten}
        onFocus={darken}
        onBlur={lighten}
        onSelect={darken}
      >
        <LeftTriangle
          borderColor={borderColor}
          backgroundColor={backgroundColor}
        />
        <ChipComponent
          variant="outlined"
          isDisabled={disabled}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          isClickable={isClickable}
          label={
            <Typography
              variant="overline"
              color={disabled ? borderColor : 'initial'}
            >
              {label}
            </Typography>
          }
          deleteIcon={<DeleteIcon />}
          {...otherChipProps}
          aria-label="tag chip"
        />
      </Box>
    </Tooltip>
  );
};

const LeftTriangle = ({ borderColor, backgroundColor }: TriangleProps) => {
  return (
    <svg height={height} width={triangleWidth}>
      <polygon
        data-testid="tag-left-triangle"
        points={`${triangleWidth},0 ${borderWidth},${
          height / 2
        } ${triangleWidth},${height}`}
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
        // remove the right border
        strokeDasharray={`${height + 4 * borderWidth}`}
      />
    </svg>
  );
};
