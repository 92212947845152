import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { Typography } from '../Typography';

type SubComponent<T extends string | ReactNode = string> = (props: {
  children: T;
}) => JSX.Element;

export const InfoPopoverFrame: SubComponent<ReactNode> = ({ children }) => (
  <Box p={2}>{children}</Box>
);

export const InfoPopoverTitle: SubComponent = ({ children }) => (
  <Typography variant="subtitle1">{children}</Typography>
);

export const InfoPopoverCopy: SubComponent = ({ children }) => (
  <Typography variant="body2">{children}</Typography>
);

export const InfoPopoverExampleBlock: SubComponent = ({ children }) => {
  const theme = useTheme();
  return (
    <Box p={1} borderRadius={1} bgcolor={theme.palette.grey[100]}>
      <Typography variant="overline">Example</Typography>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

export type InfoPopoverProps = {
  title?: string;
  copy?: string;
  example?: string;
};

export const InfoPopover = ({
  title,
  copy,
  example,
}: InfoPopoverProps): JSX.Element | null => {
  if (!title && !copy && !example) return null;

  return (
    <InfoPopoverFrame>
      {title && <InfoPopoverTitle>{title}</InfoPopoverTitle>}
      {(copy || example) && (
        <Stack gap={2}>
          {copy && <InfoPopoverCopy>{copy}</InfoPopoverCopy>}
          {example && (
            <InfoPopoverExampleBlock>{example}</InfoPopoverExampleBlock>
          )}
        </Stack>
      )}
    </InfoPopoverFrame>
  );
};
