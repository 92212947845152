import { forwardRef } from 'react';
import { Typography } from '../Typography';
import { defaultTheme } from '@automata/mui-theme';
import Stack from '@mui/material/Stack';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import FormControl from '@mui/material/FormControl';
import { InfoIconPopover, type InfoIconPopoverProps } from '../InfoIconPopover';

export type TextFieldProps = Omit<MuiTextFieldProps, 'size' | 'popover'> &
  Partial<InfoIconPopoverProps>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function Forwarded(
    {
      id = `textField-${uuidv4()}`,
      label,
      infoPopover,
      infoPopoverMaxWidth,
      infoPopoverAnchorOrigin,
      infoPopoverTransformOrigin,
      ...props
    },
    ref
  ) {
    return (
      <Stack width={props.fullWidth ? '100%' : 'inherit'}>
        {(label || infoPopover) && (
          <Typography
            htmlFor={id}
            component="label"
            variant="overline"
            color={defaultTheme.palette.text.primary}
            pt={1}
            pb={0.5}
            display="flex"
            alignItems="center"
            gap={0.5}
          >
            {label}
            {infoPopover ? (
              <InfoIconPopover
                infoPopover={infoPopover}
                infoPopoverMaxWidth={infoPopoverMaxWidth}
                infoPopoverAnchorOrigin={infoPopoverAnchorOrigin}
                infoPopoverTransformOrigin={infoPopoverTransformOrigin}
              />
            ) : null}
          </Typography>
        )}

        <FormControl>
          <MuiTextField id={id} {...props} ref={ref} size="small" />
        </FormControl>
      </Stack>
    );
  }
);
